<template>
  <div class="user-add">
    <div class="header-title">
      <i class="el-icon-back bach" @click="previous" />
      <span>创建角色</span>
    </div>
    <div>
      <div class="next-form-item-label">
        <label>角色信息</label>
      </div>
      <el-form
        ref="ruleForm"
        :inline="true"
        :model="form"
        :rules="rules"
        class="demo-form-inline"
        label-position="top"
      >
        <el-form-item label="角色名称" prop="role_name">
          <el-input v-model="form.role_name" style="width: 400px" />
        </el-form-item>
        <el-form-item label="所属域" prop="domain_id">
          <el-select v-model="form.domain_id" placeholder="请选择">
            <el-option
              v-for="item in domain_list"
              :key="item.id"
              :label="item.name"
              :value="item.id" />
          </el-select>
        </el-form-item>
        <el-row>
          <el-col :span="24">
            <el-form-item label="备注">
              <el-input
                v-model="form.remark"
                :autosize="{ minRows: 4, maxRows: 4}"
                style="width: 500px"
                type="textarea"
                :rows="2"
                placeholder=""
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <div class="btn-con" style="float: left">
            <el-button type="primary" @click="submitForm('ruleForm')">确定</el-button>
            <el-button @click="previous">取消</el-button>
          </div>
        </el-row>
      </el-form>
    </div>
  </div>
</template>

<script>
import { add_role } from '@/api/roles'
import { domain_drop_down_menu } from '@/api'

export default {
  data () {
    return {
      domain_list: [],
      form: {
        role_name: '',
        domain_id: ''
      },
      rules: {
        role_name: [{ required: true, message: '请输入角色名称', trigger: 'blur' }],
        domain_id: [{ required: true, message: '请选择所属域', trigger: 'change' }]
      }
    }
  },
  computed: {
    domainName () {
      const obj = this.domain_list.find(item => item.id == this.form.domain_id) || {}
      return obj.name ? '@' + obj.name : ''
    }
  },
  created () {
    this.get_domain_list()
  },
  methods: {
    get_domain_list () {
      domain_drop_down_menu().then(res => {
        console.log(res)
        this.domain_list = res.data
      })
    },
    submitForm (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const params = {
            role_name: this.form.role_name,
            domain_id: this.form.domain_id,
            remark: this.form.remark
          }
          add_role(params).then(res => {
            this.$message({
              showClose: true,
              message: 'success',
              type: 'success'
            })

            this.$router.push('/roles')
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    previous () {
      this.$router.push('/roles')
      this.$refs['ruleForm'].resetFields()
    }
  }
}
</script>

<style lang="less">
.user-add {
  .el-radio-group .el-radio {
    display: block;
    margin-bottom: 20px;
  }
}
</style>
